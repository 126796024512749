<template>
  <ul class="clebex-item-section">
    <li
      class="clebex-item-section-item full-right-underline"
      v-for="(key, value) in preferenceValueLabels.FIRST_DAY_OF_WEEK"
      :key="key"
      :class="preferenceUpdateData.is_automatic ? 'disabled' : ''"
      @click="setData(value)"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "preference-category", key)
          }}</label>
        </div>
        <span
          class="follow-up-icons"
          v-if="
            preferenceUpdateData.values.some(
              prefValue => prefValue.value === value
            )
          "
        >
          <span class="follow-up-icon-item">
            <icon icon="#cx-app1-checkmark" width="12" height="12"></icon>
          </span>
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
import { preferenceValueLabels } from "@/services/preference-options";
import { mapActions, mapState } from "vuex";
import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "FirstDayOfWeek",
  data() {
    return {
      preferenceValueLabels: preferenceValueLabels
    };
  },
  computed: {
    ...mapState("preferences", ["preferences", "preferenceUpdateData"])
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("preferences", ["setPreferenceUpdateData", "setPreferences"]),
    setData(value) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (this.preferenceUpdateData.is_automatic) {
        return;
      }
      const newPreferenceUpdateData = {
        is_automatic: this.preferenceUpdateData.is_automatic,
        values: [
          {
            value: value
          }
        ]
      };
      if (this.preferenceUpdateData.values[0].id) {
        newPreferenceUpdateData.values[0].id = this.preferenceUpdateData.values[0].id;
      }
      this.setPreferenceUpdateData(newPreferenceUpdateData);
      httpServiceAuth
        .put(
          `${apiEndpoints.company.userPreferences}/${this.preferenceId}`,
          this.preferenceUpdateData
        )
        .then(response => {
          const preferencesUpdateData = [...this.preferences];
          const objIndex = preferencesUpdateData.findIndex(
            obj => obj.id == response.data.data.id
          );
          preferencesUpdateData[objIndex] = response.data.data;
          this.setPreferences(preferencesUpdateData);
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.$router.push({ name: "r_preference-category" });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        });
    }
  }
};
</script>
